import io from "socket.io-client";

const logging = true;
const log = (...params) => {
    if (logging) {
        console.log(...params);
    }
}

let initialized = false;

export const initSocketConnection = (url, onMessageCallback) => {
    if (initialized) {
        return;
    }
    initialized = true;
    // Connect to the WebSocket server
    const socket = io.connect(`${url}/`, { transports: ['websocket'] });

    // Send a 'connectionType' message to indicate this is a 'Reader'
    socket.on("connect", () => socket.send(JSON.stringify({ type: 'initConnection', clientType: 'Reader' })));

    // Listen for messages from the WebSocket
    socket.on('data', (event) => {
        // Parse the received data (assuming it's in JSON format)
        const data = JSON.parse(event);
        log('ws msg:', event);

        if (data?.msg === 'data') {
            log('Received data', data.data);
            onMessageCallback(data.data);
        } else if (data?.msg === 'nodata') {
            log('No data received from server');
        } else {
            log('Unknown msg received:', data.msg);
        }
    });

    // Handle errors and other WebSocket events as needed
    socket.on('error', (event) => {
        console.error('WebSocket error:', event);
    });

    socket.on('close', (event) => {
        if (event.wasClean) {
            log('WebSocket connection closed cleanly.');
        } else {
            console.error('WebSocket connection abruptly closed.');
        }
        log('Retrying connection in 1 second');
        setTimeout(initSocketConnection(url, onMessageCallback), 1000);
    });
}
