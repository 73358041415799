import React, { useRef, useEffect } from "react";
import io from "socket.io-client";

const host = process.env.REACT_APP_HOST || 'localhost:5000';


let peerRef;
let socketRef;
let userTracks = [];
let screenTrack;

const Stream = (props) => {
    console.log('STREAM: 1');
    const userVideo = useRef();
    const userStream = useRef();

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((stream) => {
            console.log('Stream:', stream);
            userVideo.current.srcObject = stream;
            userStream.current = stream;

            socketRef = io.connect(`${host}/`);
            socketRef.emit("create room");

            socketRef.on("user joined", (userID) => {
                console.log('on("user joined"):', 'userID:', userID);
                callUser(userID);
            });

            socketRef.on("offer", handleReceiveCall);

            socketRef.on("answer", handleAnswer);

            socketRef.on("ice-candidate", handleNewICECandidateMsg);
        });

    }, []);

    function callUser(userID) {
        peerRef = createPeer(userID);
        userStream.current.getTracks().forEach((track) => {
            console.log('-----------------------------------callUser: ', 'track', track, 'userStream.current', userStream.current);
            if (track.kind === 'video' && screenTrack) {
                userTracks.push(peerRef.addTrack(screenTrack || track, userStream.current));
            } else {
                userTracks.push(peerRef.addTrack(track, userStream.current));
            }
        });
    }

    function createPeer(userID) {
        const peer = new RTCPeerConnection({
            iceServers: [
                {
                    urls: "stun:stun.stunprotocol.org"
                },
                {
                    urls: 'turn:numb.viagenie.ca',
                    credential: 'muazkh',
                    username: 'webrtc@live.com'
                },
            ]
        });

        peer.onicecandidate = (e) => handleICECandidateEvent(e, userID);
        peer.onnegotiationneeded = () => handleNegotiationNeededEvent(userID);

        return peer;
    }

    function handleNegotiationNeededEvent(userID) {
        peerRef.createOffer().then(offer => {
            return peerRef.setLocalDescription(offer);
        }).then(() => {
            const payload = {
                target: userID,
                caller: socketRef.id,
                sdp: peerRef.localDescription
            };
            socketRef.emit("offer", payload);
        }).catch(e => console.log(e));
    }

    function handleReceiveCall(incoming) {
        peerRef = createPeer();
        const desc = new RTCSessionDescription(incoming.sdp);
        peerRef.setRemoteDescription(desc).then(() => {
            userStream.current.getTracks().forEach(track => peerRef.addTrack(track, userStream.current));
        }).then(() => {
            return peerRef.createAnswer();
        }).then(answer => {
            return peerRef.setLocalDescription(answer);
        }).then(() => {
            const payload = {
                target: incoming.caller,
                caller: socketRef.id,
                sdp: peerRef.localDescription
            }
            socketRef.emit("answer", payload);
        })
    }

    function handleAnswer(message) {
        const desc = new RTCSessionDescription(message.sdp);
        peerRef.setRemoteDescription(desc).catch(e => console.log(e));
    }

    function handleICECandidateEvent(e, userID) {
        if (e.candidate) {
            // console.log('********************************', 'e.candidate', e.candidate, 'userID', userID);
            const payload = {
                target: userID,
                candidate: e.candidate,
            }
            socketRef.emit("ice-candidate", payload);
        }
    }

    function handleNewICECandidateMsg(incoming) {
        const candidate = new RTCIceCandidate(incoming);

        peerRef.addIceCandidate(candidate)
            .catch(e => console.log(e));
    }

    function shareScreen(force) {
        const getTrack = async () => {
            try {
                const stream = await navigator.mediaDevices.getDisplayMedia({ cursor: true });
                console.log('================stream', stream);
                screenTrack = stream.getTracks()[0];
                return screenTrack;
            } catch (e) {
                console.error('Error sharing screen', e);
                return null;
            }
        }

        getTrack().then((stream) => {
            if (!stream) {
                return;
            };

            // console.log('screenTrack', screenTrack);
            // console.log('stream.getTracks()[0]', stream.getTracks()[0]);
            const t = userTracks
                .filter(userTrack => userTrack.track.kind === 'video').
                forEach(userTrack => {
                    console.log('replacing userTrack', userTrack);
                    if (userTrack?.replaceTrack) {
                        userTrack.replaceTrack(screenTrack);
                    } else {
                        console.log('no userTrack');
                    }
                });
            
            screenTrack.onended = function() {
                screenTrack = null;
                userTracks
                    .filter(userTrack => userTrack.track.kind === "video")
                    .forEach(userTrack => userTrack.replaceTrack(userStream.current.getTracks()[1]));
            }
        })
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <h1>Stream</h1>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <video controls style={{height: 500, width: 500}} autoPlay ref={userVideo} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                <button onClick={() => { screenTrack = null; shareScreen(true); }}>Share screen</button>
            </div>
        </div>
    );
};

export default Stream;