import React from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';

const Loading = () => {
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: '100vh' }}
        >
            <Grid item>
                <CircularProgress color="primary" size={80} />
                <Typography variant="h6" color="textSecondary" style={{ marginTop: 16 }}>
                    Loading...
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Loading;