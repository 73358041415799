import * as React from 'react';

import { Typography, Button, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import Loading from '../../common/Loading';

const host = process.env.REACT_APP_HOST || 'localhost:5000';

const columns = [
    { field: 'id', headerName: 'ID', width: 500, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
    { field: 'type', headerName: 'Type', width: 140, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
    { field: 'time', headerName: 'Time', width: 230, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
    { field: 'dataLength', headerName: 'Data Len', width: 80, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
    { field: 'timestamp', headerName: 'Timestamp', width: 200, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
];

export default function Flights() {
    const [mlatData, setMlatData] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);
    
    React.useEffect(() => {
        fetch(`${host}/api/tempData`)
        .then((res) => res.json())
        .then((data) => {
            console.log('data:', data);
            if (data.mlatData?.length) {
                setMlatData(data.mlatData);
            } else {
                setMlatData([]);
            }
            setLoaded(true);
        });
    }, []);

    let rowCount = 0;
    const loadingElem = (<Loading />);
    const mlatElem = mlatData?.length ? (
        <>
            <Grid container spacing={2}>
                <Grid item xs={10} textAlign='left'>
                    <Typography variant="h6" gutterBottom>
                        MLAT Data
                    </Typography>
                </Grid>
                <Grid item xs={2} textAlign={'right'}>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            const data = JSON.stringify(mlatData);
                            const blob = new Blob([data], { type: 'application/json' });
                            const url = URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = `mlatdata - ${(new Date()).toISOString()}.json`;
                            a.click();
                            URL.revokeObjectURL(url);
                        }}
                    >
                        Download JSON
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <DataGrid
                        rows={
                            mlatData.reduce((acc, row) => {
                                if (!row?.id || !row?.time) {
                                    return acc;
                                }
                                
                                const date = row?.time ? new Date(row.time) : null;
                                const dateStringWithMilliseconds = !date ? null : 
                                    `${date.toDateString()} - ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}`;
                                return acc.concat([
                                    {
                                        count: rowCount++,
                                        id: `${row.id}-${row.time}`,
                                        type: row.type,
                                        time: dateStringWithMilliseconds,
                                        dataLength: row.data ? JSON.stringify(row.data).length : null,
                                        timestamp: row.time,
                                    }
                                ]);
                            }, [])
                        }
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 50 },
                            },
                            sorting: {
                              sortModel: [{ field: 'timestamp', sort: 'desc' }],
                            },
                        }}
                        pageSizeOptions={[50, 100]}
                        checkboxSelection={false}
                    />
                </Grid>
            </Grid>
        </>
    ) : (
        <Typography variant="h6" gutterBottom>
            No Flight Data Available
        </Typography>
    );

    return loaded ? mlatElem : loadingElem;
}