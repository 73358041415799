import * as React from 'react';

import { Typography, Checkbox, Grid } from '@mui/material';

const host = process.env.REACT_APP_HOST || 'localhost:5000';

export default function Settings() {
    const [showExperimentalData, setShowExperimentalData] = React.useState([]);
    
    React.useEffect(() => {
        // load showExperimentalData from localStorage
        const showExperimentalData = localStorage.getItem('showExperimentalData') === 'true';
        setShowExperimentalData(showExperimentalData);
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={10} textAlign='left'>
                <Typography variant="h6" gutterBottom>
                    Settings
                </Typography>
            </Grid>
            <Grid item xs={2} textAlign={'right'}>
            </Grid>
            <Grid item xs={12} textAlign='left'>
                <Checkbox
                    checked={showExperimentalData}
                    onChange={(event) => {
                        const showExperimentalData = event.target.checked;
                        setShowExperimentalData(showExperimentalData);
                        localStorage.setItem('showExperimentalData', showExperimentalData);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                Show experimental data
            </Grid>
        </Grid>
    );
}