import * as React from 'react';
import { Outlet, useLocation } from 'react-router';

import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import {
  Box,
  TextField,
  Button,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Badge,
  Container,
  Grid,
  Paper,
  Link,
  Tooltip,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NotificationsIcon from '@mui/icons-material/Notifications';


import LoginScreen from '../../common/Login';
import Watch from '../../common/Watch';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.seamatica.ca/">
      www.seamatica.ca
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 600;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const defaultTheme = createTheme();

export default function Layout() {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };

  const cesiumElem = (
    <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer
            variant="permanent"
            open={open}
            style={{ background: 'transparent', w: '10px' }} 
            PaperProps={
                { style: { background: open ? 'white' : 'transparent' } }
            }>
            <Toolbar sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                backgroundColor: 'transparent',
                px: [1],
            }}>
                <Tooltip title="Replay" arrow>
                    <IconButton onClick={toggleDrawer} style={{ background: 'white' }}>
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        {open ? 'Replay' : ''}
                    </IconButton>
                </Tooltip>
            </Toolbar>
            <List component="nav" style={{ display: open ? 'block' : 'none' }}>
                {/* <Watch /> */}
                {/* html video tag playing this video on repeat https://seamatica-public-videos.s3.amazonaws.com/apnt-adsb.mp4 */}
                <video controls style={{height: 350, width: 580}} autoPlay loop>
                    <source src="https://seamatica-public-videos.s3.amazonaws.com/apnt-adsb.mp4" type="video/mp4" />
                </video>
                {/* <Divider sx={{ my: 1 }} /> */}
            </List>
        </Drawer>
        <Box
            component="main"
            sx={{
            backgroundColor: 'transparent',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Outlet />
                {/* <Copyright sx={{ pt: 4 }} /> */}
            </Container>
        </Box>
    </Box>
  );

  return (
    <ThemeProvider theme={defaultTheme}>
        <LoginScreen>
            {cesiumElem}
        </LoginScreen>       
    </ThemeProvider>
  );
}