import React, { useRef, useEffect } from "react";
import io from "socket.io-client";

const host = process.env.REACT_APP_HOST || 'localhost:5000';

const Watch = (props) => {
    console.log('WATCH: 1');
    
    const peerRef = useRef();
    const socketRef = useRef();
    const otherUser = useRef();

    const partnerVideo = useRef();

    useEffect(() => {
        console.log('WATCH: useEffect');

        socketRef.current = io.connect(`${host}`);
        
        socketRef.current.on('stream available', streamerId => {
            peerRef.current = createPeer(streamerId);
            otherUser.current = streamerId;
        });
        
        socketRef.current.on("offer", handleReceiveCall);
        
        socketRef.current.on("answer", handleAnswer);
        
        socketRef.current.on("ice-candidate", handleNewICECandidateMsg);

        socketRef.current.emit("watch stream", {});
    }, []);

    function createPeer(streamerId) {
        const peer = new RTCPeerConnection({
            iceServers: [
                {
                    urls: "stun:stun.stunprotocol.org"
                },
                {
                    urls: 'turn:numb.viagenie.ca',
                    credential: 'muazkh',
                    username: 'webrtc@live.com'
                },
            ]
        });

        peer.onicecandidate = handleICECandidateEvent;
        peer.ontrack = handleTrackEvent;
        peer.onnegotiationneeded = () => handleNegotiationNeededEvent(streamerId);

        return peer;
    }

    function handleNegotiationNeededEvent(streamerId) {
        peerRef.current.createOffer().then(offer => {
            return peerRef.current.setLocalDescription(offer);
        }).then(() => {
            const payload = {
                target: streamerId,
                caller: socketRef.current.id,
                sdp: peerRef.current.localDescription
            };
            socketRef.current.emit("offer", payload);
        }).catch(e => console.log(e));
    }

    function handleReceiveCall(incoming) {
        peerRef.current = createPeer();
        const desc = new RTCSessionDescription(incoming.sdp);
        peerRef.current.setRemoteDescription(desc).then(() => {
            console.log('WATCH: handleReceiveCall', 'peerRef.current', peerRef.current, 'desc', desc);
            return peerRef.current.createAnswer();
        }).then(answer => {
            console.log('***********************************************************WATCH: handleReceiveCall', 'answer', answer);
            return peerRef.current.setLocalDescription(answer);
        }).then(() => {
            const payload = {
                target: incoming.caller,
                caller: socketRef.current.id,
                sdp: peerRef.current.localDescription
            }
            socketRef.current.emit("answer", payload);
        }).catch(e => console.log(e));
    }

    function handleAnswer(message) {
        const desc = new RTCSessionDescription(message.sdp);
        peerRef.current.setRemoteDescription(desc).catch(e => console.log(e));
    }

    function handleICECandidateEvent(e) {
        if (e.candidate) {
            const payload = {
                target: otherUser.current,
                candidate: e.candidate,
            }
            socketRef.current.emit("ice-candidate", payload);
        }
    }

    function handleNewICECandidateMsg(incoming) {
        const candidate = new RTCIceCandidate(incoming);

        peerRef.current.addIceCandidate(candidate)
            .catch(e => console.log(e));
    }

    function handleTrackEvent(e) {
        console.log('WATCH: handleTrackEvent', 'e.streams', e.streams)
        partnerVideo.current.srcObject = e.streams[0];
    };

    return (
        <div>
            <video controls style={{height: 500, width: 500}} autoPlay ref={partnerVideo} />
        </div>
    );
};

export default Watch;