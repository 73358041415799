import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './App.css';

import Router from './Router';

// https://resium.reearth.io/examples/?path=/story/entity--graphics
console.log('process.env', process.env);

function App() {
  return (
    <BrowserRouter>
        <div className="App">
            <Router />
        </div>
    </BrowserRouter>
  );
}

export default App;
