import React from 'react';
import { Grid, Typography, Link, Button, Container, Box, styled, Stack, useTheme } from '@mui/material';

import NavBar from './NavBar';


const PageSection = styled(Container)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '500px',
    width: '100%',
    height: '100%',
}));

const ImageBox = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    display: 'flex',
    alignItems: 'left',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
    margin: '10px 0',
    textAlign: 'center',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
}));

const SectionText = styled(Typography)(({ theme }) => ({
    margin: '10px 0',
    textAlign: 'center',
    fontSize: '1rem',
    color: theme.palette.primary.text,
}));

const CircularTextContainer = styled(Container)(({ theme }) => ({
    height: '500px',
    width: '500px',
    textAlign: 'center',
    borderRadius: '500px',
    backgroundColor: theme.palette.background.main,
    padding: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
}));

const WhiteBoxWithShadows = styled(Container)(({ theme }) => ({
    height: 'auto',
    width: '350px',
    padding: '50px',
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.white,
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    width: '200px',
    margin: 'auto',
    padding: '10px 20px',
    borderRadius: '20px',
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.background.green,
    '&:hover': {
        backgroundColor: theme.palette.background.greenLight,
        color: theme.palette.primary.white,
    },
}));

const LandingPage = () => {
    const theme = useTheme();
    const text = `Chronos listens to ADS-B replies from aircraft equipped with Mode A/C/S transponders, gathering precise 3D position and aircraft ID. This reported information is compared to precise measurements of the interrogating Secondary Surveillance Radar (SSR) beam timing and sweep. Using our patented Chronos Advanced Multisource Positioning (CAMP) algorithms the system independently determines the position of the responding aircraft to an accuracy <= 10 meters. Chronos is Internet-ready, running from a Power over Ethernet (PoE) supply and providing plots in GDL-90 format over TCP/IP, and is VPN and ASTERIX format capable.`;
    
    return (
        <Container className="LandingPage" sx={{
            display: { md: 'block', lg: 'flex' },
            marginBottom: '10px',
            boxSizing: { xs: 'initial', sm: 'border-box', md: 'border-box', lg: 'border-box' },
        }}>
            <Stack>
                <NavBar />
                <PageSection style={{ padding: '40px' }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Stack spacing={2}>
                                <Box sx={{
                                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
                                    height: '50px',
                                }} />
                                <SectionHeader>Making the Skies Safer</SectionHeader>
                                <SectionText>Chronos finds and validates airborne targets as well as validating your own GPS position</SectionText>
                                <SectionText>View our LIVE and recorded demos below</SectionText>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <ImageBox sx={{
                                height: '350px',
                                backgroundImage: `url(${window.location.origin + "/flight_controller.jpg"})`,
                            }} />
                        </Grid>
                    </Grid>
                </PageSection>
                <PageSection sx={{ padding: '40px', position: 'relative', display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' }}}>
                    <Box sx={{
                        flexGrow: 1,
                        display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
                        alignItems: 'left',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        position: 'absolute',
                        right: '60px',
                        top: '0',
                        width: '250px',
                        height: '180px',
                        backgroundImage: `url(${window.location.origin + "/plane.png"})`,
                    }} />
                    <Box sx={{
                        flexGrow: 1,
                        display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
                        alignItems: 'left',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        position: 'absolute',
                        left: '0px',
                        bottom: '0',
                        width: '250px',
                        height: '180px',
                        backgroundImage: `url(${window.location.origin + "/box.png"})`,
                    }} />
                    <Container>
                        <CircularTextContainer style={{ marginLeft: '180px' }}>
                            <Stack spacing={2}>
                                <SectionHeader style={{ color: theme.palette.primary.white }}>
                                    What is Chronos?
                                </SectionHeader>
                                <SectionText  style={{
                                    color: theme.palette.primary.white,
                                    maxWidth: '300px',
                                }}>
                                    Chronos is an innovative and compact device designed to use multiple sources for precise position determination of aerial targets. Chronos is the Greek primordial god of time, and the name reflects the very precise time measurements employed by our technology to determine the position and velocity of aircraft, providing reliable situational awareness of the airspace.
                                </SectionText>
                            </Stack>
                        </CircularTextContainer>
                        <CircularTextContainer style={{
                            width: '50px',
                            height: '50px',
                            top: '50px',
                            padding: '0px',
                            marginLeft: '140px',
                            marginTop: '-50px',
                        }}>
                        </CircularTextContainer>
                        <CircularTextContainer style={{
                            width: '80px',
                            height: '80px',
                            top: '80px',
                            padding: '0px',
                            marginLeft: '220px',
                            marginTop: '40px'
                        }}>
                        </CircularTextContainer>
                        <CircularTextContainer style={{
                            width: '120px',
                            height: '120px',
                            top: '120px',
                            padding: '0px',
                            marginLeft: '370px',
                            marginTop: '-40px'
                        }}>
                        </CircularTextContainer>
                    </Container>
                    <Container>
                        <WhiteBoxWithShadows style={{
                            position: 'relative',
                            top: '80px',
                            left: '-130px',
                            height: '530px'
                        }}>
                            <Stack spacing={2}>
                                <SectionHeader>
                                    How does it work?
                                </SectionHeader>
                                <SectionText  style={{ maxWidth: '300px' }}>
                                    {text}
                                </SectionText>
                            </Stack>
                        </WhiteBoxWithShadows>
                    </Container>
                </PageSection>
                <PageSection sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'none', margin: '20px 0'}}}>
                    <Container>
                            <WhiteBoxWithShadows style={{ height: 'auto', width: '100%', padding: '10px', background: theme.palette.background.main }}>
                                <Stack spacing={2} style={{ width: '100%' }}>
                                    <SectionHeader style={{ width: '100%', color: theme.palette.primary.white }}>
                                        What is Chronos?
                                    </SectionHeader>
                                    <SectionText style={{ width: '100%', color: theme.palette.primary.white }}>
                                        Chronos is an innovative and compact device designed to use multiple sources for precise position determination of aerial targets. Chronos is the Greek primordial god of time, and the name reflects the very precise time measurements employed by our technology to determine the position and velocity of aircraft, providing reliable situational awareness of the airspace.
                                    </SectionText>
                                </Stack>
                            </WhiteBoxWithShadows>
                        </Container>
                </PageSection>
                <PageSection sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'none', margin: '20px 0' }}}>
                    <Container>
                            <WhiteBoxWithShadows style={{ height: 'auto', width: '100%', padding: '10px' }}>
                                <Stack spacing={2} style={{ width: '100%' }}>
                                    <SectionHeader style={{ width: '100%' }}>
                                        How does it work?
                                    </SectionHeader>
                                    <SectionText>
                                        {text}
                                    </SectionText>
                                </Stack>
                            </WhiteBoxWithShadows>
                        </Container>
                </PageSection>
                <PageSection>
                    <Container>
                        <Stack>
                            <SectionHeader style={{ fontSize: '2.5em', margin: '50px 0px' }}>Use Cases</SectionHeader>
                            <Box>
                                <Grid container spacing={4} sx={{ alignItems: 'stretch' }}>
                                    <Grid item sm={12} md={6} sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                                        <WhiteBoxWithShadows
                                            style={{ width: '100%', borderRadius: '0px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
                                            sx={{
                                                padding: { xs: '5px', sm: '50px', md: '50px', lg: '50px' },
                                                padding: { xs: '5px', sm: '50px', md: '50px', lg: '50px' },
                                                padding: { xs: '5px', sm: '50px', md: '50px', lg: '50px' },
                                                padding: { xs: '5px', sm: '50px', md: '50px', lg: '50px' },
                                            }}
                                        >
                                            <Stack>
                                                <Stack direction="row" width='100%'>
                                                        <ImageBox sx={{
                                                            height: '100px',
                                                            width: '100px',
                                                            maxHeight: '100px',
                                                            maxWidth: '100px',
                                                            border: '0px',
                                                            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                                                            backgroundImage: `url(${window.location.origin + "/location_icon.png"})`,
                                                            backgroundSize: 'contain',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                        }} />
                                                        <Stack direction='row-reverse' width='100%'>
                                                            <ImageBox sx={{
                                                                height: '100px',
                                                                width: '100px',
                                                                maxHeight: '100px',
                                                                maxWidth: '100px',
                                                                border: '0px',
                                                                boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                                                                backgroundImage: `url(${window.location.origin + "/satellite.png"})`,
                                                                backgroundSize: 'cover',
                                                                }} />
                                                        </Stack>
                                                </Stack>
                                                <SectionHeader>GPS Validation</SectionHeader>
                                                <SectionText>
                                                    Even the best GPS receivers are prone to errors due to natural phenomena. The problem is compounded in regions subject to jamming and spoofing.  Our CAMP algorithm provides independent verification of an aircraft's reported position.
                                                </SectionText>
                                                <StyledButton color="inherit" href="/live">Live Demo</StyledButton>
                                            </Stack>
                                        </WhiteBoxWithShadows>
                                    </Grid>
                                    <Grid item sm={12} md={6} sx={{ flexGrow: 1 }}>
                                        <WhiteBoxWithShadows
                                            style={{ width: '100%', borderRadius: '0px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
                                            sx={{
                                                padding: { xs: '5px', sm: '50px', md: '50px', lg: '50px' },
                                                padding: { xs: '5px', sm: '50px', md: '50px', lg: '50px' },
                                                padding: { xs: '5px', sm: '50px', md: '50px', lg: '50px' },
                                                padding: { xs: '5px', sm: '50px', md: '50px', lg: '50px' },
                                            }}
                                        >
                                            <Stack >
                                                <Stack direction="row" width='100%'>
                                                        <ImageBox sx={{
                                                            height: '100px',
                                                            width: '100px',
                                                            maxHeight: '100px',
                                                            maxWidth: '100px',
                                                            border: '0px',
                                                            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                                                            backgroundImage: `url(${window.location.origin + "/goal_icon.png"})`,
                                                            backgroundSize: 'contain',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                        }} />
                                                        <Stack direction='row-reverse' width='100%'>
                                                            <ImageBox sx={{
                                                                height: '100px',
                                                                width: '100px',
                                                                maxHeight: '100px',
                                                                maxWidth: '100px',
                                                                border: '0px',
                                                                boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                                                                backgroundImage: `url(${window.location.origin + "/drone.png"})`,
                                                                backgroundSize: 'cover',
                                                                }} />
                                                        </Stack>
                                                </Stack>
                                                <SectionHeader>Detect and Avoid</SectionHeader>
                                                <SectionText>
                                                    DAA allows unmanned aerial vehicles and drones to integrate safely into the airspace and enables beyond visual line of sight (BVLOS) operations.  In addition to passive mode, Chronos is capable of active interrogation in low  power (whisper) or high power (shout)  modes. This allows Chronos to be deployed in SSR blind spots, such as non-towered airports.
                                                </SectionText>
                                            </Stack>
                                        </WhiteBoxWithShadows>
                                    </Grid>
                                    <Grid item sm={12} md={12}>
                                        <WhiteBoxWithShadows 
                                            style={{  width: '100%', borderRadius: '0px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
                                            sx={{
                                                padding: { xs: '5px', sm: '50px', md: '50px', lg: '50px' },
                                                padding: { xs: '5px', sm: '50px', md: '50px', lg: '50px' },
                                                padding: { xs: '5px', sm: '50px', md: '50px', lg: '50px' },
                                                padding: { xs: '5px', sm: '50px', md: '50px', lg: '50px' },
                                            }}
                                        >
                                            <Stack width='100%' style={{ }} >
                                                <Stack direction="row" width='100%'>
                                                        <ImageBox sx={{
                                                            height: '100px',
                                                            width: '100px',
                                                            maxHeight: '100px',
                                                            maxWidth: '100px',
                                                            border: '0px',
                                                            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                                                            backgroundImage: `url(${window.location.origin + "/arrow_icon.png"})`,
                                                            backgroundSize: 'contain',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                        }} />
                                                        <Stack direction='row-reverse' width='100%'>
                                                            <ImageBox sx={{
                                                                height: '100px',
                                                                width: '100px',
                                                                maxHeight: '100px',
                                                                maxWidth: '100px',
                                                                border: '0px',
                                                                boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                                                                backgroundImage: `url(${window.location.origin + "/humvee.png"})`,
                                                                backgroundSize: 'contain',
                                                                backgroundPosition: 'center',
                                                                backgroundRepeat: 'no-repeat',
                                                                }} />
                                                        </Stack>
                                                </Stack>
                                                <SectionHeader>Assured Position, Navigation and Timing</SectionHeader>
                                                <SectionText>
                                                    Today's defense environment increasingly relies on accurate, reliable and continuously available positioning for mission critical systems. The theater of operations continues to evolve rapidly with countermeasures targeting traditional GPS and GNSS systems to deny or corrupt position knowledge. A-PNT relies on a wide variety of sensors, fusing that knowledge to produce assured and accurate position determination of friendly and unfriendly targets.  Our CAMP algorithm provides the capability to incorporate multiple radio sources in the environment, such as cellular systems or beacons, to extend its capabilities beyond civilian SSR and ADS-B systems and contribute highly precise and reliable position information to A-PNT systems.
                                                </SectionText>
                                            </Stack>
                                        </WhiteBoxWithShadows>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Stack>
                    </Container>
                </PageSection>
                <PageSection sx={{
                    margin: '50px 0px',
                    padding: '0 0 20px 0',
                    backgroundColor: theme.palette.background.mainLight
                }}>
                    <Stack>
                        <SectionText sx={{ color: theme.palette.primary.white, padding: '20px' }}>
                            Want to see Chronos working in your area? Or do you have a specific need or extension to discuss? Click below and a senior member of our team will respond to your inquiry in confidence.
                        </SectionText>
                        <StyledButton href="mailto:info@seamatica.ca" color="inherit" sx={{
                            backgroundColor: theme.palette.background.purple,
                            '&:hover': {
                                backgroundColor: theme.palette.background.purpleLight,
                            }
                        }}>Contact Us</StyledButton>
                    </Stack>
                </PageSection>
                <PageSection>
                    <Grid container spacing={0}>
                        <Grid item xs={2} sm={2} md={2} lg={8}></Grid>
                        <Grid item xs={6} sm={6} md={6} lg={4}>
                            <Stack>
                                <SectionHeader style={{ textAlign: 'left', margin: 0 }}>
                                    Contact
                                </SectionHeader>
                                <SectionText style={{ textAlign: 'left' }}>
                                    Seamatica Aerospace Ltd.410
                                    <br/>
                                    East White Hills Road
                                    <br/>
                                    St. John's, NL A1A 5J7
                                    <br/>
                                    <Link href="mailto:info@seamatica.ca" color="inherit">
                                        info@seamatica.ca
                                    </Link>
                                    <br/>
                                    <Link href="tel:+18558471423" color="inherit">
                                        +1 855 847 1423
                                    </Link>
                                </SectionText>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <SectionText sx={{ color: theme.palette.primary.light }}>
                                Copyright 2024 SEAMATICA AEROSPACE LTD. All rights reserved
                            </SectionText>
                        </Grid>
                    </Grid>
                </PageSection>
            </Stack>
        </Container>
    );
};

export default LandingPage;