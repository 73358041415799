import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';

import { Viewer, Entity } from 'resium';

import { initSocketConnection } from "../socket";
import { loadPoints } from "./cesium";

const WS_URL = process.env.REACT_APP_HOST || 'localhost:5000';

const initLiveFeed = (cb) => {
    initSocketConnection(WS_URL, (dataToLoad) => {
        console.log('-------\n-------\n-------\n');
        console.log('dataToLoad:', dataToLoad);
        const newData = loadPoints([dataToLoad], true, false, true);
        console.log('after loadPoints:', newData);
        cb(newData);
    });
}

export default function Live() {
    const viewerRef = useRef(null);
    const [modelEntities, setModelEntities] = useState([]);
    const [entities, setEntities] = useState([]);

    useEffect(() => {
        initLiveFeed((newData) => {
            console.log('setting viewerData', newData);
            // setViewerData(newData);
            
            const newModelEntities = [];
            const newEntities = [];
            for (const dataSet of newData || []) {
                
                for (const track of dataSet || []) {
                    if (track) {
                        const { entities, modelEntity, mainStart, mainStop, startTime, stopTime } = track;
                        
                        if (modelEntity) {
                            newModelEntities.push(modelEntity);  
                        }
                        if (entities?.length) {
                            newEntities.push(...entities);              
                        }
                    }
                }
            }

            setModelEntities(newModelEntities);
            setEntities(newEntities);
        });
    }
    , []);

    return (
        <Grid container spacing={3}>
            <Box
                id='legend-box'
                sx={{
                    zIndex: 1000,
                    position: 'fixed',
                    right: 10,
                    bottom: 50,
                    width: 260,
                    height: 'auto',
                    padding: 2,
                    backgroundColor: '#282c34',
                    color: 'white',
                    border: '1px solid grey',}}
            >
                <Typography variant='body2'><span style={{ color: 'RED' }}>GPS (ADS-B)</span></Typography>
                <Typography variant='body2'><span style={{ color: 'LIME' }}>Passive Radar (Chronos)</span></Typography>
                {/* <Typography variant='body2'>Other track points: <span style={{ color: 'GREEN' }}>Green</span></Typography> */}
            </Box>
            <Viewer full animation={false} timeline={false} shadows={false} shouldAnimate={false}>
                {
                    modelEntities.map((modelEntity, i) => {
                        return (
                            <Entity
                                key={modelEntity.id}
                                {...modelEntity}
                            />
                        );
                    })
                }
                {
                    entities.map((entity, i) => {
                        return (
                            <Entity
                                key={entity.id}
                                {...entity}
                            />
                        );
                    })
                }
            </Viewer>
        </Grid>
    );
}