import {
    Ion,
    Viewer,
    createWorldTerrainAsync,
    createOsmBuildingsAsync,
    Cartesian3,
    Quaternion,
    Matrix3,
    Transforms,
    Math,
    JulianDate,
    SampledPositionProperty,
    Color,
    VerticalOrigin,
    HorizontalOrigin,
    ClockRange,
    NearFarScalar,
    IonResource,
    TimeInterval,
    TimeIntervalCollection,
    VelocityOrientationProperty,
    PathGraphics,
    setInterpolationOptions,
    LagrangePolynomialApproximation,
    PositionPropertyArray,
    ColorMaterialProperty,
    ColorBlendMode,
    PolygonGraphics
} from "cesium";

// import { initStore, getStore, clearAllStores } from './entityStore';

Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3MzNkMzdmZC04NDYwLTQ2M2MtYWU0Ny1mZDFhMTg0ZWU3MWYiLCJpZCI6MTY1MzM3LCJpYXQiOjE2OTQxNzgzNzd9.UdrLgwjyqX2wVn0otuuJSjwl7gkYLvkvFkpFJ191Ot8';

// Red 3D Arrow: 
// const modelId = '2500428';
// const modelId = '284726';
// const modelId = '2500563';
const modelId = '1'; // disable arrows

// White 3D Arrow: 
// const modelId = '2428076';
  
const DEFAULT_PLANE_MODEL = modelId;
const SUPPORTED_MODELS = [modelId];
const MODEL_URLS = {};
SUPPORTED_MODELS.forEach((modelId) => {
    IonResource.fromAssetId(modelId).then((modelURL) => MODEL_URLS[modelId] = modelURL);
})
  
const DRAW_ALL_PREV_POINTS = true;
  
const DATA_POINT_OPTIONS = {
    ADSB: {
        color: Color.RED,
        pixelSize: 8,
        eyeOffset: new Cartesian3(-1000, 0, -100)
    },
    Chronos: {
        color: Color.LIME,
        pixelSize: 8,
        eyeOffset: new Cartesian3(+1000, 0, +100)
    },
    FlightAware: {
        color: Color.CHARTREUSE,
        pixelSize: 8,
    },
    default: {
        color: Color.GREEN,
        pixelSize: 8,
    }
};
  
function loadModelStatic(
    trackId,
    position,
    prevPosition,
    modelId = DEFAULT_PLANE_MODEL,
    dataPoint,
) {
    try {
        // console.log('loadModelStatic', trackId, position, prevPosition, modelId, dataPoint);
        if (!SUPPORTED_MODELS.includes(modelId)) {
            modelId = DEFAULT_PLANE_MODEL;
        }

        const modelUri = MODEL_URLS[modelId];

        // if (viewer.entities.getById(trackId)) {
        //     viewer.entities.removeById(trackId);
        // }

        // console.log('loadModelStatic: position', position, 'prevPosition', prevPosition, 'modelId', modelId, 'dataPoint', dataPoint);
        var direction = Cartesian3.subtract(position, prevPosition, new Cartesian3());
        Cartesian3.normalize(direction, direction);
        
        var rotationMatrix = Transforms.rotationMatrixFromPositionVelocity(position, direction);
        
        // var rot90 = Matrix3.fromRotationY(Math.toRadians(90));
        // Matrix3.multiply(rotationMatrix, rot90, rotationMatrix);
        
        // flip model on x axis
        // rotationMatrix = Matrix3.multiply(rotationMatrix, Matrix3.fromRotationX(Math.PI), new Matrix3());
        
        // flip model on z axis
        // rotationMatrix = Matrix3.multiply(rotationMatrix, Matrix3.fromRotationY(- 1 * Math.PI / 2), new Matrix3());
        // rotationMatrix = Matrix3.multiply(rotationMatrix, Matrix3.fromRotationX(Math.PI), new Matrix3());
        rotationMatrix = Matrix3.multiply(rotationMatrix, Matrix3.fromRotationZ(Math.PI), new Matrix3());

        const heading = Quaternion.fromRotationMatrix(
            rotationMatrix
        )

        const modelEntity = {
            name: modelUri,
            id: trackId,
            position: position,
            model: {
                scale: 25,
                uri: modelUri,
                color: Color.GREY,
                colorBlendMode: ColorBlendMode.REPLACE,
            },
            orientation: heading,
            description: dataPoint ? getPointDescription(dataPoint) : trackId,
        };

        // const store = getStore(modelId);
        // store.models.push(modelEntity);

        return modelEntity;
    } catch (e) {
        console.error('loadModelStatic error', e);
        return null;
    }    
}
  
export const loadTrack = (track, live, namespace = null) => {
    const entities = [];
    let mainStart;
    let mainStop;

    // Handle track id
    const getTrackId = (point) => {
        return namespace ? `${namespace}-${point.id}` : point.id;
    }
    let id;

    let startTime;
    let stopTime;
    
    const handleTimeRange = (time) => {
        if (!startTime || JulianDate.lessThan(time, startTime)){
            startTime = time.clone();
        }
        if (!stopTime || JulianDate.greaterThan(time, stopTime)){
            stopTime = time.clone();
        }
        if (!mainStart || JulianDate.lessThan(time, mainStart)){
            mainStart = time.clone();
        }
        if (!mainStop || JulianDate.greaterThan(time, mainStop)){
            mainStop = time.clone();
        }
    };

    // Stores all timed positions of the plane in the track, used for animating flight
    const positionProperty = new SampledPositionProperty();

    // Store last two positions of the plane in the track, used to calculate model heading in live tracking
    let lastDataPoint;
    let lastPosition;
    let prevPosition;

    for(let dataPointIndx = 0; dataPointIndx < track.length; dataPointIndx++) {
        const dataPoint = track[dataPointIndx];

        // Do not load points without track id
        if (!dataPoint.id) {
            continue;
        }

        if (!id) {
            id = getTrackId(dataPoint);
        }
        
        const isoTime = (new Date(dataPoint.time)).toISOString();
        const time = JulianDate.fromIso8601(isoTime, new JulianDate()); 
        const position = Cartesian3.fromDegrees(dataPoint.longitude, dataPoint.latitude, dataPoint.height);
        
        if (!live) {
            positionProperty.addSample(time, position);
        }

        if (live && dataPointIndx === track.length - 1) {
            lastDataPoint = dataPoint;
            lastPosition = position;
        }

        if (live && dataPointIndx === track.length - 2) {
            prevPosition = position;
        }

        handleTimeRange(time);

        if (dataPointIndx === track.length - 1 || DRAW_ALL_PREV_POINTS || !live) {
            // const line = live && lastPosition && prevPosition ? {
            // 	polyline: {
            // 		positions: [lastPosition, Cartesian3.fromDegrees(track[0].longitude, track[0].latitude, track[0].height)],
            // 		width: 3,
            // 		material: Color.YELLOW,
            // 	}
            // } : {};

            const pointOptions = getPointOptions(dataPoint.type);

            entities.push({
                id: `${id}-${dataPointIndx}`,
                description: getPointDescription(dataPoint),
                position: position,
                point: pointOptions,
                // label: dataPointIndx === track.length - 1 ? {		// Only add label to the last point in each track
                //     text: dataPoint.id,
                //     verticalOrigin: VerticalOrigin.BOTTOM,
                //     horizontalOrigin: HorizontalOrigin.RIGHT,
                //     eyeOffset: pointOptions.eyeOffset,
                // } : {},
                label: {},                                              // TOREMOVE: Disable codes for now
                // ...line,
            });

            // const store = getStore(id);
            // store.points.push(addedEntity);
        }
    }
    
    let modelEntity
    if (live && id && lastPosition && prevPosition && lastDataPoint) {
        modelEntity = loadModelStatic(
            id,
            lastPosition,
            prevPosition,
            DEFAULT_PLANE_MODEL,
            lastDataPoint,
        );
    }

    return { entities, modelEntity, mainStart, mainStop, startTime, stopTime };
}
  
// const flyCameraTo = (point, height = 120000) => {
//     viewer.camera.flyTo({
//         destination: Cartesian3.fromDegrees(point.longitude, point.latitude, height)
//     });
// }
  
const getPointOptions = (dataPointType) => {
    return DATA_POINT_OPTIONS[dataPointType] ?? DATA_POINT_OPTIONS.default;
}
  
const getPointDescription = (dataPoint) => {
    const isoTime = (new Date(dataPoint.time)).toISOString();
    return `${isoTime ? `Time: ${isoTime}, ` : ''},
        ID: ${dataPoint.id},
        Lat: ${dataPoint.latitude}, 
        Lon: ${dataPoint.longitude}, 
        Alt: ${dataPoint.height}`;
};
