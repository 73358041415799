import * as React from 'react';

import { Typography, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import Loading from '../../common/Loading';

const host = process.env.REACT_APP_HOST || 'localhost:5000';

const columns = [
    // { field: 'id', headerName: 'ID', width: 100, sortable: true, filterable: true },
    { field: 'type', headerName: 'Type', width: 150, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
    { field: 'track', headerName: 'Track', width: 150, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
    { field: 'longitude', headerName: 'Longitude', width: 150, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
    { field: 'latitude', headerName: 'Latitude', width: 150, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
    { field: 'height', headerName: 'Height', width: 120, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
    { field: 'time', headerName: 'Time', width: 150, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
    { field: 'timestamp', headerName: 'Timestamp', width: 200, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
];

export default function Flights() {
    const [flights, setFlights] = React.useState([]);
    const [flightLoaded, setFlightLoaded] = React.useState(false);
    
    React.useEffect(() => {
        fetch(`${host}/api/flightData`)
        .then((res) => res.json())
        .then((data) => {
            if (data.flightData?.length) {
                setFlights(data.flightData.filter((track) => track?.length));
            }
            setFlightLoaded(true);
        });
    }, []);

    let rowCount = 0;
    const flightElem = flights?.[0]?.[0].id ? (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} textAlign='center'>
                    <Typography variant="h6" gutterBottom>
                        Flight Data
                    </Typography>
                </Grid>
                <Grid item xs={12}> 
                    <DataGrid
                        rows={
                            flights.reduce((acc, flight) => {
                                return acc.concat(flight.map((row) => {
                                    return (
                                        {
                                            ...row,
                                            count: rowCount++,
                                            track: row.id,
                                            id: `${row.id}-${row.time}`,
                                        }
                                    );
                                }));
                            }
                            , [])
                        }
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 50 },
                            },
                        }}
                        pageSizeOptions={[50, 100]}
                        checkboxSelection={false}
                    />
                </Grid>
            </Grid>
        </>
    ) : (
        <Typography variant="h6" gutterBottom>
            No Flight Data Available
        </Typography>
    );

    const loadingElem = (<Loading />);
    
    return flightLoaded ? flightElem : loadingElem;
}