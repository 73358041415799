import * as React from 'react';

import { Typography, Button, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import Loading from '../../common/Loading';

const host = process.env.REACT_APP_HOST || 'localhost:5000';

const columns = [
    { field: 'id', headerName: 'ID', width: 355, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
    { field: 'mac', headerName: 'MAC', width: 135, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
    { field: 'connected', headerName: 'Connected', width: 225, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
    { field: 'lastSeen', headerName: 'Last Seen', width: 225, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
    { field: 'messageCount', headerName: 'Messages', width: 80, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
    { field: 'dataSent', headerName: 'Data Sent', width: 120, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
    // { field: 'location', headerName: 'Location', width: 100, sortable: true, filterable: true, headerAlign: 'center', align: 'center' },
];

export default function Connections() {
    const [connStatusData, setConnStatusData] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);
    
    React.useEffect(() => {
        fetch(`${host}/admin/connStatus`)
        .then((res) => res.json())
        .then((data) => {
            console.log('conn status:', data);
            if (data.connectionStatuses?.length) {
                setConnStatusData(data.connectionStatuses);
            } else {
                setConnStatusData([]);
            }
            setLoaded(true);
        });
    }, []);

    const loadingElem = (<Loading />);
    const connStatusElem = (
        <Grid container spacing={2}>
            <Grid item xs={10} textAlign='left'>
                <Typography variant="h6" gutterBottom>
                    Connections
                </Typography>
            </Grid>
            <Grid item xs={2} textAlign={'right'}>
                <Button
                    variant="outlined"
                    onClick={() => {
                        fetch(`${host}/admin/clearConnStatus`)
                        .then((res) => res.json())
                        .then((data) => {
                            console.log('clear conn status:', data);
                            if (data.cleared) {
                                setConnStatusData([]);
                            }
                        })
                        .catch((error) => {
                            console.error('clear conn status error:', error);
                        });
                    }}
                >
                    Clear
                </Button>
            </Grid>
            <Grid item xs={12}>
                <DataGrid
                    rows={
                        connStatusData.reduce((acc, row) => {
                            if (!row.id) {
                                return acc;
                            }

                            let connected;
                            if (row.connected == null) {
                                connected = 'Unknown';
                            }
                            else if (typeof (row.connected) === 'boolean') {
                                connected = 'No';
                            }
                            else if (row.connected > 0) {
                                const date = new Date(row.connected);
                                connected = `${date.toDateString()} - ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}`;
                            }

                            let lastSeen;
                            if (!row.lastSeen) {
                                lastSeen = 'Unknown';
                            }
                            else if (row.lastSeen > 0) {
                                const date = new Date(row.lastSeen);
                                lastSeen = `${date.toDateString()} - ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}`;
                            }

                            return acc.concat({
                                id: row?.id,
                                mac: row?.info?.mac,
                                connected: connected,
                                lastSeen: lastSeen,
                                messageCount: row?.messageCount,
                                dataSent: row?.dataSent,
                                location: `${[row?.info?.lat, row?.info?.long, row?.info?.alt].filter((x) => x).join(', ')}`,
                            });
                        }, [])
                    }
                    columns={columns}
                    pageSize={50}
                    checkboxSelection={false}
                />
            </Grid>
        </Grid>
    );

    return loaded ? connStatusElem : loadingElem;
}