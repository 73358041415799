import React from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Box, styled } from '@mui/material';

const StyledNavButton = styled(Button)(({ theme }) => ({
    margin: '0px 5px',
    padding: { xs: '1px 1px', sm: '5px 15px' },
    borderRadius: '20px',
    fontSize: '16px',
    color: theme.palette.primary.text,
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.white,
    },
}));

const NavBar = () => {
    return (
        <Container>
            <Toolbar sx={{ display: 'flex' }}>
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'left', height: { xs: '20px', sm: '50px' }}}>
                    <img src={window.location.origin + "/logo.png"} alt="logo" />
                </Box>
                <StyledNavButton
                    color="inherit"    
                    sx={{
                        fontSize: { xs: '12px', sm: '12px', md: '16px' },
                    }}
                >
                    Home
                </StyledNavButton>
                <StyledNavButton
                    href="mailto:info@seamatica.ca"
                    color="inherit"
                    sx={{
                        fontSize: { xs: '12px', sm: '12px', md: '16px' },
                    }}
                >
                    Contact Us
                </StyledNavButton>
            </Toolbar>
        </Container>
    );
};

export default NavBar;