import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FlightIcon from '@mui/icons-material/Flight';
import ScreenShare from '@mui/icons-material/ScreenShare';
import Screenshot from '@mui/icons-material/ScreenshotMonitor';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';

export default function NavBar() {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <ListItemButton onClick={() => { navigate('/admin/settings'); }}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
            </ListItemButton>
            <ListItemButton onClick={() => { navigate('/admin/flights'); }}>
                <ListItemIcon>
                    <FlightIcon />
                </ListItemIcon>
                <ListItemText primary="Flight Data" />
            </ListItemButton>
            <ListItemButton onClick={() => { navigate('/admin/mlat'); }}>
                <ListItemIcon>
                    <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="MLAT Data" />
            </ListItemButton>
            <ListItemButton onClick={() => { navigate('/admin/connStatus'); }}>
                <ListItemIcon>
                    <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Connections" />
            </ListItemButton>
            {/* <ListItemButton onClick={() => { navigate('/admin/stream'); }}>
                <ListItemIcon>
                    <ScreenShare />
                </ListItemIcon>
                <ListItemText primary="Stream" />
            </ListItemButton>
            <ListItemButton onClick={() => { navigate('/admin/watch'); }}>
                <ListItemIcon>
                    <Screenshot />
                </ListItemIcon>
                <ListItemText primary="Watch" />
            </ListItemButton> */}
            {/* <ListItemButton>
            <ListItemIcon>
                <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
            </ListItemButton>
            <ListItemButton>
            <ListItemIcon>
                <LayersIcon />
            </ListItemIcon>
            <ListItemText primary="Integrations" />
            </ListItemButton> */}
        </React.Fragment>
    )
};

// export const secondaryListItems = (
//   <React.Fragment>
//     {/* <ListSubheader component="div" inset>
//       Saved reports
//     </ListSubheader>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Current month" />
//     </ListItemButton>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Last quarter" />
//     </ListItemButton>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Year-end sale" />
//     </ListItemButton> */}
//   </React.Fragment>
// );