import { loadTrack } from './tracks';

const loadTrackList = (trackList, live, namespace = null) => {
    const tracks = [];
    
    for(let trackIndx = 0; trackIndx < trackList.length; trackIndx++){
        tracks.push(loadTrack(trackList[trackIndx], live, namespace));
    }
    
    return tracks;
}

const loadTracksFromData = (dataSet, live) => {
    const tracks = [];
    
    const trackData = dataSet['pointdata'];
    tracks.push(...loadTrackList(trackData, live));
    
    const showExperimentalData = localStorage.getItem('showExperimentalData') === 'true';
    if (showExperimentalData) {
        const experimentalTrackData = dataSet['experimentalData'];
        tracks.push(...loadTrackList(experimentalTrackData, live, 'experimental'));
    }
    
    return tracks;
};
  
export const loadPoints = (flightData, clearAll = false, flyToStart = false, live = false) => {
    console.log('loadPoints: flightData', flightData, 'clearAll', clearAll, 'flyToStart', flyToStart, 'live', live);
    
    // This is not used for now
    // if (clearAll) {
    //     console.log('clearing all stores');
    //     clearAllStores();
    // }

    const res = flightData.map((dataSet) => loadTracksFromData(dataSet, live));

    return res;
}
